<template>
  <Layout>
    <b-tabs>
      <b-tab title="English">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="podcast"
                  :finishButtonText="this.$route.name == 'add-slider' ? 'Save Data' : 'Update Data'"
                  back-button-text="Go Back!" next-button-text="Go Next!">

                  <tab-content title="Slider Details" icon="mdi mdi-account-details"
                    :before-change="() => validateFormOne()">
                    <b-form-group id="input-group-1">
                      <label for="input-1">Slider Title <span style="color: red">*</span></label>
                      <b-form-input id="input-1" v-model="form.title" placeholder="Enter Slider Title" :class="{
                        'is-invalid': submitted && $v.form.title.$invalid,
                      }"></b-form-input>
                      <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                        Slider Title is required.
                      </div>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Tell Us More About The Slider" label-for="input-2"
                      class="position-relative">
                      <ckeditor v-model="form.description">
                      </ckeditor>
                    </b-form-group>
                    <div class="row">
                      <b-form-group class="col-6">
                        <label for="input-2">Type <span style="color: red">*</span></label>
                        <b-form-select id="input-2" value-field="value" text-field="text" v-model="form.type"
                          :options="type" @input="changeType" :class="{
                            'is-invalid': submitted && $v.form.type.$invalid,
                          }">
                        </b-form-select>
                        <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">
                          Type is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="date">expire slider date time <span style="color: red">*</span></label>
                        <b-form-input id="date" v-model="form.expire_slider" type="datetime-local"
                          :min="disabledDates()"
                          :class="{ 'is-invalid': submitted && $v.form.expire_slider.$invalid, }">
                        </b-form-input>
                        <div v-if="submitted && !$v.form.expire_slider.required" class="invalid-feedback">Slider
                          Expired Date is required.</div>
                      </b-form-group>
                      <template v-if="
                        form.type != null &&
                        form.type != 'home' &&
                        form.type != 'external'
                      ">
                        <b-form-group class="col-6" :label="
                          form.type == 'partner'
                            ? 'Partner'
                            : form.type == 'partner_division'
                              ? 'Partner Division'
                              : form.type == 'community'
                                ? 'Community'
                                : ''
                        " label-for="type2">
                          <template
                            v-if="form.type == 'partner' || form.type == 'partner_division' || form.type == 'community'">
                            <multiselect id="type2" v-model="form.type_id" @search-change="fetchType" :options="getType"
                              :multiple="false" track-by="id" label="title" placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.type_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.type_id.required" class="invalid-feedback">
                              <span v-if="form.type == 'partner'">Partner is required when Type is Partner</span>
                              <span v-else-if="form.type == 'partner_division'">Partner Division is required when Type
                                is Partner Division</span>
                              <span v-else-if="form.type == 'community'">Community is required when Type is
                                Community</span>
                            </div>
                          </template>
                        </b-form-group>
                      </template>
                    </div>

                    <div class="row">
                      <b-form-group label="Action Type" label-for="act" class="col-6">
                        <b-form-select id="act" v-model="form.action_type" :options="actionType" value-field="value"
                          text-field="text" @change="changeActionType">
                        </b-form-select>
                      </b-form-group>
                      <template v-if="
                        form.action_type != null &&
                        form.action_type != 'none' &&
                        form.action_type != 'external'
                      ">
                        <b-form-group class="col-6" id="input-group-3" :label="
                          form.action_type == 'expert'
                            ? 'Action ID : Expert'
                            : form.action_type == 'video'
                              ? 'Action ID : Video'
                              : form.action_type == 'live_event'
                                ? 'Action ID : Live Event'
                                : form.action_type == 'forum'
                                  ? 'Action ID : Partner Division'
                                  : form.action_type == 'news_letter'
                                    ? 'Action ID : Newsletter'
                                    : form.action_type == 'series'
                                      ? 'Action ID : Series'
                                      : form.action_type == 'news_article'
                                        ? 'Action ID : Article'
                                        : ''
                        " label-for="input-3">
                          <template v-if="form.action_type == 'news_article'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchArticle"
                              :options="getArticles" :multiple="false" track-by="id" label="card_name"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">News Article
                              is required when Action Type is News Article.</div>
                          </template>

                          <template v-else-if="form.action_type == 'expert'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchExpert"
                              :options="getExperts" :multiple="false" track-by="id" label="name"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Expert is
                              required when Action Type is Expert.</div>
                          </template>
                          <template v-if="form.action_type == 'video'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchVideo"
                              :options="getVideos" :multiple="false" track-by="id" label="title"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Video is
                              required when Action Type is Video.</div>
                          </template>
                          <template v-else-if="form.action_type == 'live_event'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchLiveEvent"
                              :options="getLiveEvents" :multiple="false" track-by="id" label="title"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Live Event is
                              required when Action Type is Live Event.</div>
                          </template>
                          <template v-else-if="form.action_type == 'forum'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchForum"
                              :options="getForums" :multiple="false" track-by="id" label="title"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Partner
                              Division is required when Action Type is Partner Division.</div>
                          </template>
                          <template v-else-if="form.action_type == 'series'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchSeries"
                              :options="getSeries" :multiple="false" track-by="id" label="title"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Series is
                              required when Action Type is Series.</div>
                          </template>
                          <template v-else-if="form.action_type == 'news_letter'">
                            <multiselect id="input-3" v-model="form.action_id" @search-change="fetchNewsletter"
                              :options="getNewsletters" :multiple="false" track-by="id" label="title"
                              placeholder="Type here to search" :class="{
                                'is-invalid': submitted && $v.form.action_id.$invalid,
                              }">
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                            <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Newsletter is
                              required when Action Type is Newsletter.</div>
                          </template>
                        </b-form-group>
                      </template>
                      <template v-else-if="form.action_type == 'external'">
                        <b-form-group class="col-6" label-for="input-group-4"
                          :label="form.action_type == 'external' ? 'Action ID : External' : ''">
                          <b-form-input id="input-group-4" v-model="form.action_id" placeholder="Enter External link"
                            :class="{
                              'is-invalid': submitted && $v.form.action_id.$invalid,
                            }">
                          </b-form-input>
                          <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">External Link is
                            required when Action Type is External Website.</div>
                        </b-form-group>
                      </template>
                    </div>
                    <div class="row">
                      <b-form-group id="input-group-4" class="col-4">
                        <label for="input-4">Thumbnail Image [Upload Max File Size : 2MB] <span
                            style="color: red">*</span></label>
                        <b-form-file id="input-4" accept="image/*" placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'image')" ref="image" :class="{
                            'is-invalid': submitted && $v.image.$invalid,
                          }"></b-form-file>
                        <template v-if="$route.name == 'edit-slider' && edit.image_url">
                          <img :src="edit.image_url" width="128px" height="128px"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <template v-if="image_url">
                          <img :src="image_url" width="128px" height="128px" ref="image_url"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <div v-if="submitted && !$v.image.required" class="invalid-feedback">Thumbnail Image is
                          required.</div>
                      </b-form-group>
                      <b-form-group class="col-4" id="input-group-5" label="Android Image [Upload Max File Size : 2MB]"
                        label-for="input-5">
                        <b-form-file id="input-5" accept="image/*" placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'android_image')" ref="android_image"></b-form-file>
                        <template v-if="$route.name == 'edit-slider' && edit.android_image_url">
                          <img :src="edit.android_image_url" width="128px" height="128px"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <template v-if="android_image_url">
                          <img :src="android_image_url" width="128px" height="128px"
                            style="object-fit: contain; margin-top: 5px" ref="android_image_url" />
                        </template>
                      </b-form-group>
                      <b-form-group class="col-4" id="input-group-6" label="IOS Image [Upload Max File Size : 2MB]"
                        label-for="input-6">
                        <b-form-file id="input-6" accept="image/*" placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'ios_image')" ref="ios_image"></b-form-file>
                        <template v-if="$route.name == 'edit-slider' && edit.ios_image_url">
                          <img :src="edit.ios_image_url" width="128px" height="128px"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <template v-if="ios_image_url">
                          <img :src="ios_image_url" width="128px" height="128px" ref="ios_image_url"
                            style="object-fit: contain; margin-top: 5px" />
                        </template>
                      </b-form-group>
                    </div>
                  </tab-content>

                  <tab-content title="Add Tags" icon="fa fa-tag">
                     <div class="row">
                        <b-form-group class="col-6">
                           <div class="d-flex justify-content-between align-items-center mb-1">
                              <label for="comm" class="mb-0">Speciality
                                 <span style="color: red"> *</span></label>
                              <b-form-checkbox v-model="status" button button-variant="info" size="sm">
                                 <template v-if="status">Unselect All</template>
                                 <template v-else>Select All</template>
                              </b-form-checkbox>
                           </div>
                           <multiselect @search-change="fetchSpeciality" id="comm"
                              v-model="form.speciality_selected" :options="speciality" :multiple="true"
                              track-by="id" label="title" placeholder="Type here to search" :class="{
                                 'is-invalid':
                                    store &&
                                    $v.form.speciality_selected
                                       .$invalid,
                              }">
                              <span slot="noOptions">
                                 Type here to search
                              </span>
                           </multiselect>
                           <div v-if="
                              store &&
                              !$v.form.community_selected.required
                           " class="invalid-feedback">
                              Community is required.
                           </div>
                        </b-form-group>
                        <b-form-group label="Country" label-for="input-multi" class="col-6">
                           <multiselect @search-change="fetchCountry" id="input-multi" v-model="form.country"
                              :options="allCountryWithAll" :multiple="true" track-by="id" placeholder="Type here to search"
                              label="name">
                              <span slot="noOptions">Type here to search</span>
                           </multiselect>
                        </b-form-group>
                     </div>
                    <b-form-group label="Enter Free tags" label-for="tags-separators">
                      <b-form-tags input-id="tags-separators" v-model="form.tags" tag-variant="primary" tag-pills
                        separator=" " placeholder="Enter new tags separated by space and enter">
                      </b-form-tags>
                    </b-form-group>
                    <div class="row">
                      <b-form-group id="input-group-7" label="Meta Title" label-for="input-7"
                        class="col-6 position-relative">
                        <b-form-input id="input-7" :maxlength="250" v-model="form.meta_title"
                          placeholder="Enter Meta Title">
                        </b-form-input>
                        <div class="text-right">
                          <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.meta_title.length !== 250,
                              'badge-danger': form.meta_title.length === 250
                            }">
                            You typed
                            {{ form.meta_title.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6 position-relative" id="input-group-9" label="Meta Keywords"
                        label-for="input-9">
                        <b-form-input id="input-9" :maxlength="250" v-model="form.meta_keywords"
                          placeholder="Enter Meta Keywords">
                        </b-form-input>
                        <div class="text-right">
                          <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.meta_keywords.length !== 250,
                              'badge-danger': form.meta_keywords.length === 250
                            }">
                            You typed
                            {{ form.meta_keywords.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                    </div>
                    <b-form-group id="input-group-8" label="Meta Description" label-for="input-8"
                      class="position-relative">
                      <b-form-textarea id="input-8" v-model="form.meta_desc" :maxlength="240" placeholder="Enter Meta Description"
                        rows="3"></b-form-textarea>
                      <div class="text-right">
                        <p v-if="form.meta_desc" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                          'badge-success': form.meta_desc.length !== 240,
                          'badge-danger': form.meta_desc.length === 240
                        }">
                          You typed
                          {{ form.meta_desc.length }} out of 240 chars.
                        </p>
                      </div>
                    </b-form-group>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Indonesia">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <b-form-group>
                  <label for="translation-title">Slider Title</label>
                  <b-form-input id="translation-title" v-model="form.translation.indonesia.title"
                    placeholder="Enter Slider Title"></b-form-input>
                </b-form-group>
                <b-form-group label="Tell Us More About The Slider" label-for="translation-desc">
                  <ckeditor v-model="form.translation.indonesia.description">
                  </ckeditor>
                </b-form-group>
                <div class="row">
                  <b-form-group class="col-4">
                    <label for="translation-thumbnail">Thumbnail Image [Upload Max File Size : 2MB]</label>
                    <b-form-file id="translation-thumbnail" accept="image/*"
                      placeholder="Choose a file or drop it here..." @change="readFile($event, 'indonesia_image')"
                      ref="indonesia_image"></b-form-file>
                    <template v-if="$route.name == 'edit-slider' && edit.indonesia_image_url">
                      <img :src="edit.indonesia_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="indonesia_image_url">
                      <img :src="indonesia_image_url" width="128px" height="128px" ref="indonesia_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-4" label="Android Image [Upload Max File Size : 2MB]"
                    label-for="translation-android">
                    <b-form-file id="translation-android" accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'indonesia_android_image')" ref="indonesia_android_image"></b-form-file>
                    <template v-if="$route.name == 'edit-slider' && edit.indonesia_android_image_url">
                      <img :src="edit.indonesia_android_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="indonesia_android_image_url">
                      <img :src="indonesia_android_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" ref="indonesia_android_image_url" />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-4" label="IOS Image [Upload Max File Size : 2MB]"
                    label-for="translation-ios">
                    <b-form-file id="translation-ios" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'indonesia_ios_image')" ref="indonesia_ios_image"></b-form-file>
                    <template v-if="$route.name == 'edit-slider' && edit.indonesia_ios_image_url">
                      <img :src="edit.indonesia_ios_image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="indonesia_ios_image_url">
                      <img :src="indonesia_ios_image_url" width="128px" height="128px" ref="indonesia_ios_image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                </div>
                <div class="row">
                  <b-form-group class="col-6" label="Meta Title" label-for="translation-meta-title">
                    <b-form-input id="translation-meta-title" v-model="form.translation.indonesia.meta_title"
                      placeholder="Enter Meta Title"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-6" label="Meta Keywords" label-for="translation-meta-keywords">
                    <b-form-input id="translation-meta-keywords" v-model="form.translation.indonesia.meta_keywords"
                      placeholder="Enter Meta Keywords"></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group label="Meta Description" label-for="translation-meta-desc" class="position-relative">
                  <b-form-textarea id="translation-meta-desc" v-model="form.translation.indonesia.meta_desc"
                    placeholder="Enter Meta Description" rows="3"></b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import sliderMixin from "../../../mixins/ModuleJs/slider";
import { required, requiredIf } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      store: false,
      submitted: false,
      title1: "Add Slider",
      title2: "Edit Slider",
      items: [
        {
          text: "Back",
          href: "/slider",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, sliderMixin],
  components: {
    Layout,
    FormWizard,
    TabContent,
    Multiselect
  },
  validations: {
    form: {
      title: { required },
      type: { required },
      type_id: { required: requiredIf(form => form.type == 'partner' || form.type == 'partner_division' || form.type == 'community') },
      action_id: { required: requiredIf(form => form.action_type == 'video' || form.action_type == 'expert' || form.action_type == 'live_event' || form.action_type == 'news_letter' || form.action_type == 'series' || form.action_type == 'news_article' || form.action_type == 'forum' || form.action_type == 'external') },
      speciality_selected: { required },
      expire_slider: { required },
    },
    image: { required }
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid || this.$v.form.type.$invalid || this.$v.form.type_id.$invalid || this.$v.form.action_id.$invalid || this.$v.image.$invalid || this.$v.form.expire_slider.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
  },
};
</script>
